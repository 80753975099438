/* global google */
import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import greyCursor from '../../img/greyCursor128.png';
import { useTranslation } from 'react-i18next';
import useFetch from '../../shared/hooks/useFetch';
import { addresses } from '../../config/api/addresses';
import { useNavigate } from 'react-router-dom';
import Loader from '../../shared/components/loader';


function Blog({ scrollToStartBox, scrollToHowItWorks }) {

    const { t, i18n } = useTranslation();
    const { data: blogs, isLoading: loadingBlogs } = useFetch('get', {
        autoGetUrl: `${addresses.blog}?language=${i18n.language || 'en'}`,
        mapper(res) {
            console.log(res)
            return res?.results
        }
    })
    const navigate = useNavigate()

    const onClickGetOffer = () => {
        navigate('/sell/calculate-offer')
        setTimeout(() => {
            scrollToStartBox()
        }, 200);
    }

    const onClickHowItWorks = () => {
        navigate('/sell/calculate-offer')
        setTimeout(() => {
            scrollToHowItWorks()
        }, 300);
    }
    return (

        <div className='blogContainer'>
            <div className="blogTitleContainer">
                <span>Blogs</span>

            </div>
            <div className="blog">
                {blogs?.map((blog => {
                    return (
                        <div className="box blogBox" key={blog.id}>
                            <img src={blog.cover_image} alt={blog.cover_image_alt} className="blogCoverPhoto" />
                            <h1 className='blogTitle'>{blog.title}</h1>
                            <p className='blogSubtitle'>{blog.subtitle}</p>
                            <p className='blogReadMore' onClick={() => { navigate(`${blog.id}`); }}>Read More</p>
                        </div>
                    )
                }))}
            </div>
            {loadingBlogs && <Loader />}
            <div className="footer">
                <div className="quick-description-container">
                    <div className={`quick-description2`}>
                        {t('header.subTitleStatement')}
                    </div>
                    <button
                        onClick={onClickGetOffer}
                        className={`instruction-button orange-button`}
                    >
                        {t('intro.instructions')}
                        <img src={greyCursor} alt="Grey Cursor" className="cursor1" />
                    </button>
                    <button
                        onClick={onClickHowItWorks}
                        className={`instruction3-button`}
                    >
                        {t('intro.instructions2')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Blog;