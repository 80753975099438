// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loader__hSxP1 {
    margin: auto;
    border: 16px solid #f3f3f3;
    /* Light grey background */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: loader_spin__uDQNN 2s linear infinite;
}

@keyframes loader_spin__uDQNN {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/loader/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0BAA0B;IAC1B,0BAA0B;IAC1B,8BAA8B;IAC9B,SAAS;IACT,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gDAAkC;AACtC;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader {\n    margin: auto;\n    border: 16px solid #f3f3f3;\n    /* Light grey background */\n    border-top: 16px solid #3498db;\n    /* Blue */\n    border-radius: 50%;\n    width: 120px;\n    height: 120px;\n    animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_loader__hSxP1`,
	"spin": `loader_spin__uDQNN`
};
export default ___CSS_LOADER_EXPORT___;
