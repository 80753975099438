import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../shared/hooks/useFetch';
import { addresses } from '../../config/api/addresses';
import { useTranslation } from 'react-i18next';
import Loader from '../../shared/components/loader';

const BlogPost = () => {
    const { postId } = useParams();
    const { i18n } = useTranslation();
    const { data: blog, isLoading: loadingBlog } = useFetch('get', {
        autoGetUrl: `${addresses.blog}${postId}/?language=${i18n.language || 'en'}`,
    })
    return loadingBlog ? <div style={{ height: "500px", alignContent: 'center' }}><Loader /> </div> : <div style={{ padding: '24px', boxSizing: 'border-box', height: '500px', overflow: 'auto', width: '100vw' }} dangerouslySetInnerHTML={{ __html: blog?.content }} />;
};

export default BlogPost;
